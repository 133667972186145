<template>
  <div
    id="login"
    style="overflow: hidden; width: 100%; height: 100vh"
    class="d-flex align-center"
    :class="$vuetify.breakpoint.mobile ? 'px-10' : 'px-15'"
  >
    <v-form class="flex-grow-1" v-model="formvalid">
      <div
        v-if="$vuetify.breakpoint.mobile"
        class="d-flex justify-center flex-column mb-5"
      >
        <div class="primary--text mb-3" style="font-size: 7vw">
          {{ $t("GENERAL.welcome") }}
          <span class="font-weight-black">{{ $t("GENERAL.title") }}</span>
        </div>
        <div>
          <textra
            class="primary--text"
            style="font-size: 3.3vw"
            :data="[
              $t('GENERAL.features.book', {
                count: $doctor.getLocalDocs().length
              }),
              $t('GENERAL.features.advice'),
              $t('GENERAL.features.update')
            ]"
            :infinite="true"
            :timer="5"
            filter="right-left"
          />
        </div>
      </div>
      <v-text-field
        hide-details="auto"
        background-color="rgba(165, 203, 239, 0.42)"
        filled
        rounded
        color="primary"
        :rules="emailRules"
        required
        dense
        single-line
        v-model.trim="form.email"
        :label="$t('AUTH.LOGIN.EMAIL')"
        value=" "
      />
      <v-text-field
        hide-details="auto"
        background-color="rgba(165, 203, 239, 0.42)"
        class="mt-3"
        type="password"
        :rules="[v => !!v || $t('AUTH.LOGIN.PASSVALIDATION')]"
        required
        filled
        rounded
        dense
        single-line
        color="primary"
        v-model="form.password"
        :label="$t('AUTH.LOGIN.PASSWORD')"
        value=" "
      />
      <v-snackbar v-model="snackbar" multi-line timeout="5000" color="error">
        {{ errors }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <div class="mt-5">
        <v-btn
          @click="submit"
          :disabled="submitted && !errors.length"
          :loading="submitted && !errors.length"
          rounded
          block
          elevation="0"
          color="primary"
          >{{ $t("AUTH.LOGIN.BUTTON") }}</v-btn
        >
        <v-fade-transition>
          <router-link :to="{ name: 'reset_password' }">
            <v-btn
              rounded
              v-show="!showformonly"
              class="mt-3"
              block
              small
              text
              color="primary"
            >
              {{ $t("AUTH.FORGOT.TITLE") }}
            </v-btn>
          </router-link>
        </v-fade-transition>
      </div>
      <v-fade-transition>
        <v-divider v-show="!showformonly" />
      </v-fade-transition>
      <v-fade-transition>
        <v-row v-show="!showformonly" class="mt-3">
          <v-col
            cols="12"
            lg="6"
            md="6"
            v-if="$util.getSettings().open_registration"
          >
            <router-link :to="{ name: 'register' }">
              <v-btn
                rounded
                elevation="0"
                block
                color="green darken-3"
                class="white--text"
                >{{ $t("AUTH.REGISTER.BUTTON") }}</v-btn
              >
            </router-link>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.mobile" cols="12" lg="6" md="6">
            <v-btn
              @click="opendocreg = !opendocreg"
              block
              rounded
              text
              color="primary"
            >
              {{ $t("AUTH.GENERAL.SIGNUP_HERE") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>
    </v-form>

    <v-bottom-sheet v-if="$vuetify.breakpoint.mobile" v-model="opendocreg">
      <v-card class="" elevation="0">
        <v-toolbar elevation="0" class="text-primary">
          <v-toolbar-title>
            {{ $t("AUTH.REG_STEPS.doc.title") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon dark color="primary" @click="opendocreg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="py-5 pb-10 text-center text-muted text-size-h2">
          {{ $t("AUTH.REG_STEPS.doc.body") }}
        </div>
      </v-card>
    </v-bottom-sheet>
    <v-dialog
      v-if="!$vuetify.breakpoint.mobile"
      v-model="opendocreg"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("AUTH.REG_STEPS.doc.title") }}
        </v-card-title>

        <v-card-text>
          {{ $t("AUTH.REG_STEPS.doc.body") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="opendocreg = false">
            {{ $t("POPUPS.okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import { Plugins } from "@capacitor/core";

const { Keyboard } = Plugins;

export default {
  name: "login",

  components: {},
  data() {
    return {
      languageFlag: "",
      opendocreg: false,
      showformonly: false,
      initialheight: 0,
      snackbar: false,
      // Remove this dummy login info
      formvalid: false,
      emailRules: [
        v => !!v || this.$t("AUTH.LOGIN.EMAILVALIDATION"),
        v => /.+@.+/.test(v) || this.$t("AUTH.LOGIN.EMAILVALIDATION")
      ],
      submitted: false,
      form: {
        email: "",
        password: ""
      }
    };
  },
  mounted() {
    this.$root.loader = false;
    document.getElementById("login").addEventListener("touchmove", e => {
      e.preventDefault();
    });

    Keyboard.addListener("keyboardWillShow", () => {
      // console.log("keyboard will show with height", info.keyboardHeight);
      this.showformonly = true;
    });

    Keyboard.addListener("keyboardWillHide", () => {
      // console.log("keyboard will hide");
      this.showformonly = false;
    });
  },
  methods: {
    onResize() {
      if (this.initialheight === 0) this.initialheight = window.innerHeight;
      if (window.innerHeight < this.initialheight) {
        this.showformonly = true;
      } else {
        this.showformonly = false;
      }
      // console.log("***** new height *****", window.innerHeight);
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    submit() {
      if (this.formvalid) {
        this.submitted = true;
        const email = this.form.email;
        const password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(async () => {
            await this.$user.fetchCurrentUser().then(async () => {
              let user = this.$user.getCurrentUser();

              this.$util.newDS();
              this.$util.startUpDS(user);
              this.$user.logAccess(this.$util.getDeviceInfo());
              if (process.env.NODE_ENV === "production") {
                this.$rocket.identify(user.external_id, {
                  name: user.first_name + " " + user.last_name,
                  email: user.email
                });
              }

              if (user.role.id == 4) {
                //loading mf
                await this.$file.getItemByID(user.id, true).then(async data => {
                  // console.log("mdeical file is", data, user);
                  if (data === undefined) {
                    await this.$file.createItem(user).then(data => {
                      // console.log("new mf is", data.data.data);
                      this.$root.mf = data.data.data.id;
                      this.$file.data.currentFile = data.data.data;
                      this.progress += 10;
                    });
                  } else {
                    this.$root.mf = data.id;
                    this.progress += 10;
                  }
                });
              } else if (user.role.id == 3) {
                // console.log("doctor role is", user.role.id, "user is", user);
                await this.$doctor.getItemByID(user.id, true).then(data => {
                  // console.log("doctor is", data);
                  this.$root.docID = data.id;
                });
              }
              this.loader = false;
            });
            this.$root.currentpage = "dashboard";
            this.$router.push({ name: "dashboard" });
          });
      }
    }
  },
  watch: {
    errors: function(newerr) {
      if (newerr.length > 0) {
        this.snackbar = true;
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
<style>
.v-application ul {
  padding-left: 0px !important;
}
.theme--light.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  padding: 15px 18px !important;
  background-color: #f3f6f9 !important;
  color: rgb(63, 66, 84) !important;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot {
  background-color: rgb(232, 240, 254) !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  background-color: transparent !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  input,
label {
  color: rgb(63, 66, 84) !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f6f9 inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(232, 240, 254) inset;
}

.animate_ar_mobile {
  margin-right: auto;
  margin-left: auto;
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}

.animate_en_mobile {
  margin-right: auto;
  margin-left: auto;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
  z-index: 0;
}
</style>
